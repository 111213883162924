@import '../../scss/mixins.module.scss';

.AccountForm {
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;

  &Card {
    width: 21rem;
  }

  .AccountField {

    @include text-input-field();

    &:not(:last-of-type) {
      margin-bottom: 1rem;
    }
  }

  .AccountActions {
    flex: 1;
    justify-content: space-between;
    flex-direction: row-reverse;
  }
  .AccountAvatarActionIcons {
    justify-content: center;
  }
}

@import '../../scss/mixins.module.scss';

.AuthForm {
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .AuthField {
    @include text-input-field();

    &:not(:last-of-type) {
      margin-bottom: 1rem;
    }
  }
}

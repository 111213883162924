.Panel {
  width: 100%;
  max-height: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-color: var(--mdc-theme-surface, #fcfffb);
  position: fixed;
  z-index: 2;
  transition: box-shadow 200ms linear;
  transition: max-height 0.3s ease-in;

  &Open {
    max-height: 260px;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
      0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    transition: box-shadow 200ms linear;
    transition: max-height 0.3s ease-out;
  }

  &Header {
    max-height: 52px;
    padding: 8px 16px 9px;
  }

  &Body {
    padding: 0 16px 16px;
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    overflow-x: hidden;
    background-color: var(--mdc-theme-surface, #fcfffb);
  }

  &Footer {
    max-height: 36px;
    background-color: var(--mdc-theme-surface, #fcfffb);
    padding: 8px;
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: space-between;
  }
}
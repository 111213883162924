.RelativePosition {
  position: relative;
}

.InfoPosition { 
  position: absolute;
  top: 0;
  right: -21px;
  line-height: 0;
  z-index: 900;
}

.SelectStartingWordOverlay {
  display: flex;
  gap: 8px;
  min-width: 242px;
  [class*="WordleSelector"] {
    margin-bottom: 0;
    resize: none;
    flex: 1;
  }
}

.EditFirstWordPopup {
  position: absolute;
  top: 0;
  right: -21px;
  line-height: 0;
  z-index: 1001;
}

.PlaceholderMessage {
  svg {
    vertical-align: text-bottom;
  }
}

.DisplayContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  align-items: center;
  position: relative;
}

.GuessContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}

.ButtonOneRow {
  display: flex;
  justify-content: space-between;
}
.ControlContainer {
  position: relative;
  max-width: 270px;
  flex: 1;
  display: flex;
  gap: 8px;
  flex-direction: column;
  font-size: 22px;
  input,
  textarea {
    width: 50px;
    height: 50px;
    text-align: center;
    font-size: 32px;
    font-weight: 700;
    // text-transform: uppercase;
    &.match {
      background-color: var(--mdc-theme-puzzle-exists, #ffb003);
      border: thin solid var(--mdc-theme-puzzle-exists, #ffb003);
      // color: white;
    }
    &.exactMatch {
      background-color: var(--mdc-theme-puzzle-exact-match, #5fc405);
      border: thin solid var(--mdc-theme-puzzle-exact-match, #5fc405);
      // color: white;
    }
    &.miss {
      background-color: var(--mdc-theme-puzzle-miss, #c2b9b9);
      border: thin solid var(--mdc-theme-puzzle-miss, #c2b9b9);
      // color: white;
    }
  }
}
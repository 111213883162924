@import "./scss/custom.module.scss";

:root {
  --mdc-theme-primary: #69a267;
  --mdc-theme-secondary: #00cb00;
  --mdc-theme-surface: #edf8ee;
  // --mdc-theme-background: #f9fffa;
  --mdc-theme-background: #fcfffb;
  // --mdc-theme-on-primary: #12c612;
  --mdc-theme-on-primary: #096809;
  --mdc-theme-on-secondary: #096809;
  --mdc-theme-on-surface: #096809;
  --mdc-theme-on-hint: #096809;
  --mdc-theme-text-primary-on-background: #096809;
  --mdc-theme-text-secondary-on-background: #ecffec;
  --mdc-theme-text-surface-on-background: #091309;
  --mdc-theme-text-hint-on-background: #096809;
  --mdc-theme-text-primary-on-light: #096809;
  --mdc-theme-text-secondary-on-light: #096809;
  --mdc-theme-puzzle-miss: #c2b9b9;
  --mdc-theme-puzzle-exact-match: #5fc405;
  --mdc-theme-puzzle-exists: #ffb003;
  --mdc-theme-puzzle-primary: #091309;
  --mdc-theme-puzzle-secondary: #0c63e4;
  --mdc-theme-puzzle-secondary-rgb: 12, 99, 228;
}

body {
  // background-color: var(--mdc-theme-background, #edf8ee);
  margin: 0;
  padding: 0;
  min-height: calc(var(--vh, 1vh) * 100);
  font-family: -apple-system, BlinkMacSystemFont, "Roboto", "Segoe UI", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  min-height: calc(var(--vh, 1vh) * 100);
  width: 100%;
}

.app-fab--absolute {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
}

// @media (max-width: 599px) {
//   .mdc-top-app-bar--fixed-adjust {
//     padding-top: 128px;
//   }
// }

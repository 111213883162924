@import '../../../../scss/mixins.module.scss';

.Cell {
  input,
  textarea {
    width: 50px;
    height: 50px;
    text-align: center;
    font-size: 32px;
    font-weight: 700;
    @include to-upper-case();
  }

  &.Selected {
    box-sizing: border-box;
    // box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.55);
    outline: rgba(#2684FF, 0.75) auto 1px;
    border-radius: 3px;
  }

  &.Match {
    background-color: var(--mdc-theme-puzzle-exists, #ffb003);
    border: thin solid var(--mdc-theme-puzzle-exists, #ffb003);
  }
  &.ExactMatch {
    background-color: var(--mdc-theme-puzzle-exact-match, #5fc405);
    border: thin solid var(--mdc-theme-puzzle-exact-match, #5fc405);
  }
  &.Miss {
    background-color: var(--mdc-theme-puzzle-miss, #c2b9b9);
    border: thin solid var(--mdc-theme-puzzle-miss, #c2b9b9);
  }
}

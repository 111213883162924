.StatsContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: space-between;

  input {
    width: 25px;
    height: 25px;
  }
  label {
    // padding-left: 8px;
    outline: none;
    width: 120px;
    font-size: 16px;
    font-weight: 300;
    vertical-align: top;
  }
}

.Spacer {
  flex: 1;
  max-width: 90px;
}

.Totals {
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;

  input {
    width: 25px;
    height: 25px;
  }
  label {
    // padding-left: 8px;
    // width: 120px;
    font-size: 16px;
    font-weight: 300;
    vertical-align: top;
  }
}
.TotalLetterLabel {
  font-size: 24px;
  font-weight: 500;
}
.TotalCountLabel {
  font-size: 24px;
  font-weight: 600;
}

.letterLabel {
  font-weight: 500;
}
.countLabel {
  font-weight: 600;
}

.ButtonContainer {
  display: flex;
  justify-content: space-between;
  gap: 8px;
}

.StatsLetterContainer {
  max-width: 300px;
  flex: 1;
  display: flex;
  gap: 8px;
  flex-direction: row;
  font-size: 22px;
  input,
  textarea {
    width: 50px;
    height: 50px;
    text-align: center;
    font-size: 32px;
    font-weight: 700;
    outline-color: var(--mdc-theme-puzzle-miss, #c2b9b9);
  }
  &.Match {
    background-color: var(--mdc-theme-puzzle-exists, #ffb003);
    border: thin solid var(--mdc-theme-puzzle-exists, #ffb003);
    outline-color: var(--mdc-theme-puzzle-exists, #ffb003);
    // color: white;
  }
  &.ExactMatch {
    background-color: var(--mdc-theme-puzzle-exact-match, #5fc405);
    border: thin solid var(--mdc-theme-puzzle-exact-match, #5fc405);
    outline-color: var(--mdc-theme-puzzle-exact-match, #5fc405);
    // color: white;
  }
  &.Miss {
    background-color: var(--mdc-theme-puzzle-miss, #c2b9b9);
    border: thin solid var(--mdc-theme-puzzle-miss, #c2b9b9);
    outline-color: var(--mdc-theme-puzzle-miss, #c2b9b9);
    // color: white;
  }
}

.WordCountBadge {
  position: relative;
  left: -15px;
  top: -20px;
}

.LetterCount {
  display: inline-block;
}

.TotalCountStats {
  line-height: 45px;
}

label.LetterButton {
  outline: none;
  font-weight: 700;
  width: 90px;
}

button.DeleteButton {
  line-height: 0;
  width: 90px;
}

@media only screen and (max-width: 480px) {
  .StatsContainer {
    label.LetterButton {
      width: 40px;
      font-weight: 700;
      &:active,
      &:hover,
      &:focus &:focus-visible {
        outline: none;
        box-shadow: none;
      }
    }
    /* Workaround for toggle style for letter buttons not in a word */
    .LetterButton[class*="btn-outline-secondary"] {
      &:active,
      &:hover,
      &:focus {
        color: #6c757d;
        background-color: transparent;
      }
    }
    
    button.DeleteButton {
      width: 40px;
      svg {
        width: 14px;
      }
    }

    .LetterCount {
      display: none;
    }
  }
  .TotalLetterLabel + .TotalCountLabel {
    display: none;
  }
  .Spacer {
    max-width: 40px;
  }
}

@media only screen and (max-width: 320px) {
  .StatsContainer {
    label.LetterButton {
      width: 40px;
      font-weight: 700;
      &:active,
      &:hover,
      &:focus &:focus-visible {
        outline: none;
        box-shadow: none;
      }
    }
    /* Workaround for toggle style for letter buttons not in a word */
    .LetterButton[class*="btn-outline-secondary"] {
      &:active,
      &:hover,
      &:focus {
        color: #6c757d;
        background-color: transparent;
      }
    }
    
    button.DeleteButton {
      width: 40px;
      svg {
        width: 14px;
      }
    }

    .LetterCount {
      display: none;
    }
  }
  .TotalLetterLabel + .TotalCountLabel {
    display: none;
  }
  .Spacer {
    max-width: 40px;
  }
}


.MatchSpotCheckbox {
  padding-top: 24px;
}

span.CellLetter {
  border: thin solid var(--mdc-theme-puzzle-miss, #c2b9b9);
  margin: 3px;
  width: 25px;
  height: 25px;
  display: inline-block;
  text-align: center;
  font-weight: 600;
}

span.MissLetter {
  border: thin solid var(--mdc-theme-puzzle-miss, #c2b9b9);
  margin: 1px;
  width: 15px;
  height: 15px;
  display: inline-block;
  text-align: center;
  font-weight: 300;
  font-size: small;
  color: var(--mdc-theme-puzzle-primary, #091309);
}

.ModalStats {
  font-family: 'Dosis', sans-serif;
  font-weight: 500;

  h5,
  button {
    color: #0c63e4;
  }
}

.ModalWordList {
  text-align: center;
}

div.ModelFooterAlignCenter {
  justify-content: center;
  display: flex;
  flex-direction: column;

  .MissedLetters {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }
}

span.Match {
  background-color: var(--mdc-theme-puzzle-exists, #ffb003);
  border: thin solid var(--mdc-theme-puzzle-exists, #ffb003);
  outline-color: var(--mdc-theme-puzzle-exists, #ffb003);
  // color: white;
}
span.ExactMatch {
  background-color: var(--mdc-theme-puzzle-exact-match, #5fc405);
  border: thin solid var(--mdc-theme-puzzle-exact-match, #5fc405);
  outline-color: var(--mdc-theme-puzzle-exact-match, #5fc405);
  // color: white;
}
span.Miss {
  background-color: var(--mdc-theme-puzzle-miss, #c2b9b9);
  border: thin solid var(--mdc-theme-puzzle-miss, #c2b9b9);
  outline-color: var(--mdc-theme-puzzle-miss, #c2b9b9);
  // color: white;
}

.MatchTypeSelectionLabel {
  margin-top: 8px;
}


.StatsInfoOverlay[class*="popover"] {
  font-family: 'Dosis', sans-serif;
  font-weight: 500;

  [class*="popover-header"] {
    background-color: #0dcaf0;
    margin: 2px;
  }
}
@import '../../../scss/mixins.module.scss';

.AvatarSelectorForm {
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;

  &Card {
    width: 21rem;
  }

  .AvatarSelectorActions {
    flex: 1;
    justify-content: space-between;
    flex-direction: row-reverse;
  }
  .AvatarSelectorActionIcons {
    justify-content: center;
  }
}
